  
  export default function Events() {
	return (

<div id="events" class="relative bg-whitea pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
  <div class="absolute inset-0">
    <div class="bg-white h-1/3 sm:h-2/3"></div>
  </div>
  <div class="relative max-w-7xl mx-auto">
    <div class="text-center">
      <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">Events</h1>
      <p class="mt-3 max-w-2xl mx-auto text-3xl text-gray-500 sm:mt-4">Participate in five different events that each highlight a great feature of SwiftUI</p>
    </div>
    <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

    <a href="/speedrun" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-60 w-full object-cover" src="/images/events/speedrun.png" alt="" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-3xl font-semibold text-gray-900"> Speedrun</p>
              <div>Monday May 23</div>
              <p class="mt-3 text-base text-gray-500">The rules are simple. You get an app design screenshot and your goal is to remake it in SwiftUI. Points for speed AND accuracy, so be sure to balance the two!</p>
            </div>
          </div>
        </div>
      </a>
      
      <a href="/workarounds" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-60 w-full object-cover" src="/images/events/workarounds.png" alt="" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-3xl font-semibold text-gray-900">Workarounds & Hacks</p>
              <div>Tuesday May 24</div>
              <p class="mt-3 text-base text-gray-500">Do you know every little hack to get around the growing pains of SwiftUI? In this event you will have to get creative!</p>
            </div>
          </div>
          
        </div>
      </a>

      <a href="/animations" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-60 w-full object-cover" src="/images/events/animations.png" alt=""/>
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-3xl font-semibold text-gray-900">Animations</p>
              <div>Wednesday May 25</div>
              <p class="mt-3 text-base text-gray-500">Make an app shine by adding functional, informative and guiding animations!</p>
            </div>
          </div>
        </div>
      </a>

      

      <a href="/macos" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-60 w-full object-cover" src="/images/events/macos.png" alt="" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-3xl font-semibold text-gray-900">macOS</p>
              <div>Thursday May 26</div>
              <p class="mt-3 text-base text-gray-500">The Mac is more popular than ever, so this event is for everyone who likes making utility apps.</p>
            </div>
          </div>
          
        </div>
      </a>

      <a href="/accessibility" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-60 w-full object-cover" src="/images/events/accessibility.png" alt=""/>
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-3xl font-semibold text-gray-900">Accessibility</p>
              <div>Friday May 27</div>
              <p class="mt-3 text-base text-gray-500">Start from a sample project that is unusable for a lot of users with disabilities and make it as accessible as you can! Show the world how easy it is to make accessible apps with SwiftUI, there are no excuses!</p>
            </div>
          </div>
        </div>
      </a>
      
    </div>
  </div>
</div>

	)
  }

