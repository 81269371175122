import React from 'react';


import Hero from '../Components/Hero';
import Header from '../Components/Header';
import CTA from '../Components/CTA';
import DubDubBanner from '../Components/dubdubbanner';
import Footer from '../Components/Footer';
import Features from '../Components/Features';
import Events from '../Components/Events';
import FAQ from '../Components/FAQ';
import Judges from '../Components/Judges';

import {Helmet} from "react-helmet";

const Home = () => (
  <>
  <Helmet>
    <meta name="SwiftUI Series" content=""/>
    <meta name="keywords" content="SwiftUI, Series"/>
    <meta name="description" content="A week-long event to learn, compete and socialise in the world of SwiftUI"/>
  
  
    <meta property="og:title" content="The SwiftUI Series" />
    <meta property="og:url" content="https://www.swiftuiseries.com" />
    <meta property="og:image" content="https://www.swiftuiseries.com/images/dubdubseries.png" />

    
    <meta name="twitter:title" content="The SwiftUI Series"/>
    <meta name="twitter:description" content="A week-long event to learn, compete and socialise in the world of SwiftUI"/>
    <meta name="twitter:image" content="https://www.swiftuiseries.com/images/social.jpg"/>

    <meta name="twitter:card" content="summary_large_image" />

    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

    <title>The SwiftUI Series</title>
    
  </Helmet>
    <Hero />
    <DubDubBanner />
    <Events />
    <Judges />
    <FAQ />
    <Footer />
  </>
);
export default Home;

