import React from 'react';

const DubDubBanner = () => (

<div class="bg-red-500">
  <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
	<h2 class="text-3xl font-extrabold text-white sm:text-4xl">
	  <span class="block">Play and Learn Together</span>
	  <span class="block">during WWDC</span>
	</h2>
	<p class="mt-4 text-lg leading-6 text-white">After a week of discovering all the new things that are announced, build something fun, useful, cool, strange or smart with the new APIs and share it with the world! Submit your code and join us on Friday as we explore new possibilities together!</p>
	<a href="https://www.swiftuiseries.com/dubdubseries" class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-xl text-red-600 bg-white hover:bg-white sm:w-auto"> More about the Dub Dub Series</a>
  </div>
</div>

 );
 export default DubDubBanner;
 
 