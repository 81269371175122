import './App.css';
import Header from './Components/Header';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

import Home from './pages/home.js';

import SpeedRun from './pages/speedrun.js';
import Animations from './pages/animations.js';
import Accessibility from './pages/accessibility.js';
import MacOS from './pages/macos.js';
import Workarounds from './pages/workarounds.js';
import DubDubSeries from './pages/dubdubseries.js';

function App() {
  return (
    <div className="App">
      <Router>
        
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/speedrun' element={<SpeedRun />} />
          <Route path='/animations' element={<Animations />} />
          <Route path='/workarounds' element={<Workarounds />} />
          <Route path='/macos' element={<MacOS />} />
          <Route path='/accessibility' element={<Accessibility />} />
          <Route path='/dubdubseries' element={<DubDubSeries />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
