import React from 'react';
import {Helmet} from "react-helmet";

import Hero from '../Components/Hero';
import Header from '../Components/Header';
import CTA from '../Components/CTA';
import Footer from '../Components/Footer';
import Features from '../Components/Features';
import Events from '../Components/Events';
import FAQ from '../Components/FAQ';
import Judges from '../Components/Judges';

const DubDubSeries = () => (
  <>
	<Helmet>
		<meta name="Dub Dub Series" content=""/>
		<meta name="keywords" content="Dub Dub, SwiftUI, Series"/>
		<meta name="description" content="Play and Learn Together during WWDC"/>
	
		<meta property="og:title" content="Dub Dub Series" />
		<meta property="og:url" content="https://www.swiftuiseries.com/dubdubseries" />
		<meta property="og:image" content="https://www.swiftuiseries.com/images/dubdubseries.png" />
	
		<meta name="twitter:title" content="Dub Dub Series"/>
		<meta name="twitter:description" content="Play and Learn Together during WWDC"/>
		<meta name="twitter:image" content="https://www.swiftuiseries.com/images/dubdubseries.png"/>
		<meta name="twitter:card" content="summary_large_image"/>
		<title>Dub Dub Series</title>
	</Helmet>
  <div>      
  	<figure class="sm:flex sm:justify-center lg:justify-start">
	  <img class="max-w-7xl mx-auto" src="/images/dubdubseries.png" alt="" />
	</figure>
	
	
  
  
  <div class="relative bg-white py-16 sm:py-24">
  <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
	<div class="relative sm:py-16 lg:py-0">
	  <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
		<div class="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72"></div>
		<svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
		  
		  <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
		</svg>
	  </div>
	  <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
		
		<div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
		  <img class="absolute inset-0 h-full w-full object-cover" src="/images/ios16logo.jpg" alt=""/> 
		</div>
	  </div>
	</div>
  
	<div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
	  
	  <div class="pt-12 sm:pt-16 lg:pt-20">
		<h2 class="text-5xl text-gray-900 font-extrabold sm:text-5xl">WWDC 2022 Challenge</h2>
		<div class="mt-6 text-gray-500 space-y-6">
		  <p class="text-3xl">This week Apple announced a ton of new APIs and frameworks that allow developers to make new and better experiences. Using 200 lines of code or less, practice making something new, and show the world what iOS 16 is capable of! Grab your favorite new API and take it out for a spin!</p>
		</div>
	  </div>
  
	  
	  <div class="mt-10">
		<dl class="grid grid-cols-1 gap-x-4 gap-y-8">  
		  <div class="border-t-2 border-gray-100 pt-6">
			<dt class="text-base font-medium text-gray-500">Event Video Starting Time</dt>
			<u><a href="https://everytimezone.com/s/d707ac18" class="text-3xl font-extrabold tracking-tight text-gray-900">Friday June 10 09:30 PDT</a></u>
		  </div>
  
  
		  <div class="border-t-2 border-gray-100 pt-6">
			<dt class="text-base font-medium text-gray-500">Entry Deadline</dt>
			<u><a href="https://everytimezone.com/s/3bf5c80b" class="text-3xl font-extrabold tracking-tight text-gray-900">Friday June 10 08:00 PDT</a></u>
		  </div>
  
	  
		</dl>
		<div class="mt-10">
		  <a href="https://github.com/SwiftUI-Series/DubDubSeries" class="text-base text-3xl font-medium text-red-600">Submit your entry<span aria-hidden="true"> &rarr;</span> </a>
		</div>
	  </div>
	</div>
  </div>
  
  
  
  <div class="bg-white">
	  <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8">
		<div class="space-y-12">
		  <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
			<h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Requirements</h2>
		  </div>
		  <div class="mt-6 prose prose-indigo prose-lg text-gray-800">
		  <ul role="list">
			   <li>Open a new Xcode project and make a new iOS or iPadOS app</li>
			   <li>Using less than 200 lines of code and a single SwiftUI file, show off a newly annouced API / framework / feature from iOS 16 (Here's a <a href="https://developer.apple.com/whats-new/">list of new APIs</a> to get you started)</li>
			   <li>Create a new public repo for your code</li>
			   <li>Add a reference to your project to the README of this repo and make a PR</li>			  
			 </ul>
		  </div>
		</div>
	  </div>
	</div>
	
	  
  </div>
  

  
  <div class="bg-white">
	<div class="max-w-5xl mx-auto py-16 px-4 sm:pt-24 sm:px-6 lg:px-8">
	  <div class="text-center">
		<p class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Play and Learn Together <br/>during WWDC</p>
		<p class="max-w-4xl mt-5 mx-auto text-xl text-gray-500"> 
		After a week of discovering all the new things that are announced, build something fun, useful, cool, strange or smart with the new APIs and share it with the world! Submit your code and join us on Friday as we explore new possibilities together!
		</p>
	  </div>
	</div> 
	<div class="max-w-7xl mx-auto text-center px-4 sm:pb-20 sm:px-6 lg:px-8">
		 <div class="rounded-2xl">
			<a href="https://forms.gle/y4CUqzyPy5FE2UB77" class="  px-8 py-3 border border-transparent  font-bold rounded-2xl text-white bg-black hover:bg-black md:py-4 md:text-xl md:px-10">Join for free!</a>
		  </div>
	   </div>
  </div>


  <Footer />
  </div>
</>
 );
 export default DubDubSeries;
 
 