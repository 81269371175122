/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link 
} from 'react-router-dom';


export default function Example() {

  useEffect(() => {
console.log('testss')
     var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.countapi.xyz/hit/com.goodsnooze.supportersite/home");
    xhr.responseType = "json";
    xhr.onload = function() {
        document.getElementById('visits').innerText = this.response.value;
    }
    xhr.send();
  }, []);

  const [pageURL, setPageURL] = useState(0);
  useEffect(() => {
    console.log('test')
    setPageURL(window.location.href);
  })

const endPoints = ['howDidYouFindUs1', 'howDidYouFindUs2', 'howDidYouFindUs3']

const [item, setItem] = useState(0);

  return (
    <div>      
     <main class="lg:relative">
     
     <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img class="absolute inset-0 w-full h-full object-cover" src="/images/hero-side-.png" alt="SwiftUI Series Logo" />
      </div>
       <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
         <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
           <p class="mt-3 max-w-md mx-auto text-4xl text-black sm:text-3xl md:mt-5 md:max-w-3xl">Thanks to everyone who participated and watched the first SwiftUI Series! It was a week-long event to learn, compete and socialise in the world of SwiftUI. The next edition is on <u><a href="https://www.swiftuiseries.com/dubdubseries">June 10th</a></u> and will focus on new APIs announced at WWDC.</p>
           
           <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
           
             <div class="rounded-2xl ">
               <a href="https://forms.gle/NqMB6XbwYxUJ6HyV9" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-2xl text-white bg-black hover:bg-black md:py-4 md:text-xl md:px-10">Join Mailinglist</a>
             </div>
             
             <div class="rounded-2xl shadow mt-2 sm:mt-0 sm:ml-3">
               <a href="#faq" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-2xl text-white bg-gray-400 hover:bg-gray-500 md:py-4 md:text-xl md:px-10">Questions?</a>
             </div>
           </div>
           
         </div>
       </div>
       
      
       
     </main>
      
</div>
  )
}

     