import React from 'react';
import {Helmet} from "react-helmet";

const Animations = () => (
  <>
	<Helmet>
		<meta name="Animations - SwiftUI Series" content=""/>
		<meta name="keywords" content="SwiftUI, Series"/>
		<meta name="description" content="A week-long event to learn, compete and socialise in the world of SwiftUI"/>
	
		<meta property="og:title" content="Animations - SwiftUI Series" />
		<meta property="og:url" content="https://www.swiftuiseries.com/animations" />
		<meta property="og:image" content="https://www.swiftuiseries.com/images/events/animations.png" />
	
		<meta name="twitter:title" content="Animations - SwiftUI Series"/>
		<meta name="twitter:description" content="A week-long event to learn, compete and socialise in the world of SwiftUI"/>
		<meta name="twitter:image" content="https://www.swiftuiseries.com/images/events/animations.png"/>
		<meta name="twitter:card" content="summary_large_image"/>
	</Helmet>
  <div class="relative bg-white py-16 sm:py-24">
	<div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
	  <div class="relative sm:py-16 lg:py-0">
		<div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
		  <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
		  <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
			
			<rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
		  </svg>
		</div>
		<div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
		  
		  <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
			<img class="absolute inset-0 h-full w-full object-cover" src="/images/events/animations.png" alt=""/> 
		  </div>
		</div>
	  </div>
  
	  <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
		
		<div class="pt-12 sm:pt-16 lg:pt-20">
		  <h2 class="text-5xl text-gray-900 font-extrabold sm:text-5xl">Animations</h2>
		  <div class="mt-6 text-gray-500 space-y-6">
			<p class="text-3xl">
			Twitter’s like and Medium’s cumulative clap reaction animations help people to express emotions and react to tweets and articles in ways words cannot. Your job is to recreate/clone, or build something similar to the following animations.
			</p>
		  </div>
		</div>
  
		
		<div class="mt-10">
		  <dl class="grid grid-cols-1 gap-x-4 gap-y-8">  
			<div class="border-t-2 border-gray-100 pt-6">
			  <dt class="text-base font-medium text-gray-500">Event Video Starting Time</dt>
			  <u><a href="https://everytimezone.com/s/c70c8d22" class="text-3xl font-extrabold tracking-tight text-gray-900">Wednesday May 25 17:00 CEST</a></u>
			</div>
  
			<div class="border-t-2 border-gray-100 pt-6">
			  <dt class="text-base font-medium text-gray-500">Entry Deadline</dt>
			  <u><a href="https://everytimezone.com/s/c70c8d22" class="text-3xl font-extrabold tracking-tight text-gray-900">Wednesday May 25 17:00 CEST</a></u>
			</div>
  
		
		  </dl>
		  <div class="mt-10">
			<a href="https://github.com/SwiftUI-Series/Animations" class="text-base text-3xl font-medium text-indigo-600">Submit your entry<span aria-hidden="true"> &rarr;</span> </a>
		  </div>
		</div>
	  </div>
	</div>
	<div class="bg-white">
		<div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8">
		  <div class="space-y-12">
			<div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
			  <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Requirements</h2>
			</div>
			<div class="mt-6 prose prose-indigo prose-lg text-gray-800">
			<ul role="list">
				<li>Make a micro interaction animation for a like / support / clap button as seen below</li>
				<li>Get creative because the main goal is to delight users with a fun little animation</li>
				<li>You can use <u><a href="https://apps.apple.com/us/app/motionscape-animation-studio/id1616840951">MotionsScape</a></u> in your solution to help you select your SwiftUI animation easings/interpolations.</li>
				 <li>Upload your code to a public GitHub repo and add it to the <u><a href="https://github.com/SwiftUI-Series/animations">SwiftUI Series Animations Repo</a></u></li>
				 <li>You can check out MotionScape's code on <u><a href="https://github.com/GetStream/motionscape-app">GitHub</a></u></li>
			   </ul>
			</div>
		  </div>
		</div>
	  </div>
	  
	  <div class="bg-white">
			<div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
		  		<div class="space-y-12">
				<div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
			    	<h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Samples</h2>
			    	<p>Twitter’s Like Reaction Animation/Interaction.</p>
				</div>
			
					  <img class="w-max shadow-lg rounded-lg" src="https://github.com/GetStream/swiftui-series-animation-challenge/raw/main/Misc/TwitterLikeReaction.gif" alt="" />
					  <img class="w-max shadow-lg rounded-lg" src="https://github.com/GetStream/swiftui-series-animation-challenge/raw/main/Misc/mediumClap.gif" alt="" />
			</div>
			</div>

		  
		
	  </div>
	  
	<div class="bg-white">
		<div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
		  <div class="space-y-12">
			<div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
			  <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Judges</h2>
			</div>
			<ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
			  <li>
				  <div class="space-y-4">
					<div class="aspect-w-3 aspect-h-2">
					  <img class="object-cover shadow-lg rounded-lg" src="images/judges/amos.jpg" alt="Amos" />
					</div>
				
					<div class="space-y-2">
					  <div class="text-lg leading-6 font-medium space-y-0">
						<h3 class="text-2xl font-bold" >Amos Gyamfi</h3>
						<p class="text-indigo-600">Animations Judge</p>
					  </div>
					  <ul role="list" class="flex space-x-5">
						<li>
						  <a href="https://www.twitter.com/amos_gyamifi" class="text-gray-400 hover:text-gray-500">
							<span class="sr-only">Twitter</span>
							<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
							  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
							</svg>
						  </a>
						</li>
					  </ul>
					</div>
				  </div>
				</li>
				<li>
					<div class="space-y-4">
					  <div class="aspect-w-3 aspect-h-2">
						<img class="object-cover shadow-lg rounded-lg" src="images/judges/rambo.jpg" alt="Robin" />
					  </div>
				  
					  <div class="space-y-2">
						<div class="text-lg leading-6 font-medium space-y-0">
						  <h3 class="text-2xl font-bold">Guilherme Rambo</h3>
						  <p class="text-indigo-600">Animations Judge</p>
						</div>
						<ul role="list" class="flex space-x-5">
						  <li> 
							<a href="https://www.twitter.com/_inside" class="text-gray-400 hover:text-gray-500">
							  <span class="sr-only">Twitter</span>
							  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
								<path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
							  </svg>
							</a>
						  </li>
						</ul>
					  </div>
					</div>
				  </li>
			</ul>
		  </div>
		</div>
	  </div>
	  <div class="bg-white">
		<div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
		  <div class="space-y-12">
			<div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
			  <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Watch the event</h2>
			  <p>Watch the Animations event below.</p>
			</div>
			<div>
			  <div class="aspect-w-16 aspect-h-9">
					<iframe src="https://www.youtube.com/embed/_LZimxDnAeU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
			  </div>
			</div>
		  </div>
		</div>
	  </div>
  </div>
</>
 );
 export default Animations;
 
 
 
 
 