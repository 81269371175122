  
  export default function FAQ() {
	return (
	  <div id="faq" class="bg-gray-50">
 		<div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
		<h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
		<dl class="mt-6 space-y-6 divide-y divide-gray-200">
		<div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900"> When will the SwiftUI Series take place? </span>
    </dt>
    <dd class="mt-2 pr-12" id="faq-0">
      <p class="text-base text-gray-500">The first SwiftUI Series will take place from <b>May 23 until May 27</b>. Sign up <u><a href="https://forms.gle/TNPtbppTigN2bxT4A">now</a></u> to be the first to hear more details.</p>
    </dd>
  </div>
  <div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900"> I am new to SwiftUI, can I still participate? </span>  
    </dt>
    <dd class="mt-2 pr-12" id="faq-1">
      <p class="text-base text-gray-500">Yep! The SwiftUI Series will have events for people who are new to SwiftUI as well as people who consider themselves great at it! The beginner level events will feature less complicated challenges and you will be able to learn from the solutions of others as well!</p>
    </dd>
  </div>
  <div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900">Does it cost money to participate?</span>
    </dt>
    <dd class="mt-2 pr-12" id="faq-2">
      <p class="text-base text-gray-500">Participating in the SwiftUI Series is completely free.</p>
    </dd>
  </div>
  <div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900">How many events can I compete in?</span>
    </dt>
    <dd class="mt-2 pr-12" id="faq-2">
      <p class="text-base text-gray-500">You can compete in as many events as you would like!</p>
    </dd>
  </div>
  <div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900">Is it possible to sponsor The SwiftUI Series?</span>
    </dt>
    <dd class="mt-2 pr-12" id="faq-2">
      <p class="text-base text-gray-500">I've decided not to take on any sponsors for this first event. I would like to thank Stream, RevenueCat and Agora for offering to help out with the costs of the event though! 😍</p>
    </dd>
  </div>
  <div class="pt-6">
		<dt class="text-lg">
        <span class="font-medium text-gray-900">How will the events work during the SwiftUI Series?</span>
    </dt>
    <dd class="mt-2 pr-12" id="faq-2">
      <p class="text-base text-gray-500">The week before the event the challenges will be posted. For some events you will be able to submit a GitHub repo with your code, while others require you to upload a screenrecording as well as the code.</p>
    </dd>
  </div>
      </dl>
    </div>
  </div>
</div>
	)
  }


